import FF from "../../components/forms/FormField.module.css";

import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../../components/forms/FormField.tsx";
import { showWarn } from "../../dev/errorStream.ts";
import { LocationSelectCustom } from "./LocationSelect.tsx";
import { MultiLocationSelectCustom } from "./MultiLocationSelect.tsx";
import type { LocationsForType } from "./location.ts";

/** NB: form must implement this */
export interface LocationInputs {
    location: string;
}

export interface LocationFieldProps {
    locations: LocationsForType;
    useNewSelect?: boolean;
}

export function LocationField({ locations, useNewSelect }: LocationFieldProps) {
    const form = useFormContext<LocationInputs>();
    const { control } = form;
    return (
        <Controller
            control={control}
            name="location"
            render={({ field }) => (
                <LocationFieldCustom
                    value={field.value}
                    onChange={field.onChange}
                    locations={locations}
                    useNewSelect={useNewSelect}
                />
            )}
        />
    );
}

export function LocationFieldCustom({
    value,
    onChange,
    locations: { locations, showLocations },
    useNewSelect,
}: LocationFieldProps & {
    value: string;
    onChange: (location: string) => void;
}) {
    if (!showLocations) {
        showWarn("LocationField: This component should not be rendered when showLocation is false.");
        return null;
    }

    return (
        <FormField
            data-testid="location-field"
            wide
            label="Location"
            isRequired
            description="Data center where the resource will be created."
        >
            {useNewSelect ? (
                <LocationSelectCustom items={locations} valueKey={value} onChange={(l) => onChange(l.slug)} />
            ) : (
                <div className={FF.FormFieldRadioGroup}>
                    <MultiLocationSelectCustom locations={locations} defaultValue={value} onChange={onChange} />
                </div>
            )}
        </FormField>
    );
}
