import FF from "../../components/forms/FormField.module.css";

import { useState } from "react";
import { groupBy } from "remeda";
import { Tile, TileSelect } from "../../components/forms/TileSelect.tsx";
import { CountryFlag } from "./CountryFlag.tsx";
import type { LocationWithEnabled } from "./location.ts";
import { getIso3CountryInfo } from "./locationCountry.ts";
import { LocationName } from "./LocationDetail.tsx";
import type { LocationSlug } from "./query.ts";

export interface LocationRadioProps {
    locations: LocationWithEnabled[];

    defaultValue?: LocationSlug;
    onChange: (slug: LocationSlug) => void;

    isLoading?: boolean;
}

export function MultiLocationSelectCustom({ locations, defaultValue, onChange }: LocationRadioProps) {
    const [value, setValue] = useState<LocationSlug | undefined>(defaultValue);

    const groupedItems = groupBy(locations, (l) => l.country_code);

    const tiles = Object.entries(groupedItems).map(([code, locations]) => {
        return (
            <Tile<LocationSlug>
                key={code}
                data-testid={`locationSelectCountry--${code}`}
                rememberLastSelected
                icon={() => <CountryFlag code={code} className={FF.countryFlag} />}
                title={getIso3CountryInfo(code)?.name}
                items={locations.map((location) => ({
                    key: location.slug,
                    value: location.slug,
                    disabled: !location.isEnabled,
                    label: <LocationName location={location} />,
                }))}
            />
        );
    });

    return (
        <TileSelect<LocationSlug>
            value={value}
            equal={(a, b) => a === b}
            onChange={(value) => {
                setValue(value);
                onChange(value);
            }}
        >
            {tiles}
        </TileSelect>
    );
}
